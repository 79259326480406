import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { useEffect, useRef, useState } from 'react';
import * as THREE from 'three'
import { useLocation } from 'react-router-dom';
import './index.scss'

import BWDist from '../../shaders/basic/BWDistFragment.glsl'
import centerBlur from '../../shaders/basic/centerBlurFragment.glsl'
import chelouFragment from '../../shaders/basic/chelouFragment.glsl'
import colorSwirl from '../../shaders/basic/colorSwirl.glsl'
import colorTint from '../../shaders/basic/colorTintFragment.glsl'
import dispF from '../../shaders/basic/dispFragment.glsl'
import disp2F from '../../shaders/basic/disp2Fragment.glsl'
import distortion from '../../shaders/basic/distortionFragment.glsl'
import edge from '../../shaders/basic/edgeFragment.glsl'
import edgeInvert from '../../shaders/basic/edgeInverse.glsl'
import heatmap from '../../shaders/basic/heatmpFragment.glsl'
import fragment2 from '../../shaders/basic/fragmentShader.glsl'
import pixelsF from '../../shaders/basic/pixelsFragment.glsl'
import repetitionF from '../../shaders/basic/repetitionFragment.glsl'
import tintF from '../../shaders/basic/tintFragment.glsl'
import tourbillonF from '../../shaders/basic/tourbillonFragment.glsl'
import tourbillon2F from '../../shaders/basic/tourbuillon2Fragment.glsl'
import tourneF from '../../shaders/basic/tourneFragment.glsl'
import tvF from '../../shaders/basic/tvFragment.glsl'
import waterF from '../../shaders/basic/waterFragment.glsl'

import HearthAnything from '../../assets/anything-hearth.png'

import testVertexShader from '../../shaders/basic/vertexShader.glsl'

const VideoMaterial = () => {
    const location = useLocation()
    const currentFragment = () => {
        // location.hash

        let fragments = {
            BWDist,
            centerBlur,
            chelouFragment,
            colorSwirl,
            colorTint,
            dispF,
            disp2F,
            distortion,
            edge,
            edgeInvert,
            heatmap,
            fragment2,
            pixelsF,
            repetitionF,
            tintF,
            tourbillonF,
            tourbillon2F,
            tourneF,
            tvF,
            waterF,
        }

        return fragments["heatmap"]
    }

    const videoRef = useRef();
    const [videoTexture, setVideoTexture] = useState(null);
    const [videoWidth, setVideoWidth] = useState(null)
    const [videoHeight, setVideoHeight] = useState(null)
    const materialRef = useRef()
    
    const planeGeometryRef = useRef()
    const { gl, viewport, size } = useThree()
  
    useEffect(() => {
      const video = document.createElement('video');
      video.autoplay = "autoplay";
      video.playsInline = "true"
      video.muted = "muted";
      video.loop = true;
  
      const handleStream = (stream) => {
        video.srcObject = stream;
        console.log(stream)
        video.play();
        setVideoHeight(stream.getVideoTracks()[0].getSettings().height);
        setVideoWidth(stream.getVideoTracks()[0].getSettings().width);
        const texture = new THREE.VideoTexture(video);
        setVideoTexture(texture);
        videoRef.current = video;
      };
  
  
      const handleError = (error) => {
        console.error('Error accessing webcam:', error);
      };
  
      navigator.mediaDevices.getUserMedia({ video: true }).then(handleStream).catch(handleError);
  
      return () => {
        if (video.srcObject) {
          video.srcObject.getTracks().forEach(track => track.stop());
        }
      };
    }, []);
  
    useFrame((state) => {
      if (videoTexture) {
        videoTexture.needsUpdate = true;
        const { clock } = state;
  
        if (materialRef.current) {
          materialRef.current.uniforms.uTexture.value = videoTexture;
          materialRef.current.uniforms.uTime.value = clock.getElapsedTime();
        }
      }
    });
  
    if (!videoTexture) return null;
  
    // Calculate the aspect ratios
    const viewportAspect = viewport.width / viewport.height;
    let videoAspect = videoWidth / videoHeight;
  
  
    const isPortrait = viewport.height > viewport.width;
    if (isPortrait) {
      videoAspect = videoHeight / videoWidth
    }
  
    // Calculate the size of the plane geometry based on the aspect ratios
    let width, height;
    if (viewportAspect > videoAspect) {
      height = viewport.height;
      width = videoAspect * height;
    } else {
      width = viewport.width;
      height = width / videoAspect;
    }
  
    return (
      <mesh scale={[width, height, 1]}>
        <planeGeometry ref={planeGeometryRef} args={[1, 1]} />
        <shaderMaterial 
          uniforms={{
            uTexture: { value: videoTexture },
            uTime: { value: 0.0 },
            uColor: { value: new THREE.Vector3(0.6, 0.6, 0.0) },
            uIteration: { value: 3.0 },
            uRes: { value: new THREE.Vector2(1.8, 1) }
          }} ref={materialRef} fragmentShader={currentFragment()} vertexShader={testVertexShader} map={videoTexture} />
      </mesh>
    );
  };

const Official1 = () => {
    const handleSnapshot = () => {
      const canvas = document.querySelector('canvas');
      const link = document.createElement('a');
      link.href = canvas.toDataURL('image/png');
      link.download = 'snapshot.png';
      link.click();
    };

    return (
      <>
        <div className="toPicTake">
          <Canvas style={{ height: "100vh", width: "100vw" }}>
            <VideoMaterial />
          </Canvas>
          <img src={HearthAnything} alt="" />
        </div>
        <button
          onClick={handleSnapshot}
          style={{
            position: "absolute",
            top: "10px",
            left: "10px",
            zIndex: "1000",
          }}
        >
          Take Snapshot
        </button>
      </>
    );
}

export default Official1