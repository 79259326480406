// Tourne
uniform sampler2D uTexture;
uniform float uTime;
varying vec2 vUv;

void main() {
    vec2 uv = vUv - 0.5;
    float angle = uTime;
    float radius = length(uv);
    float theta = atan(uv.y, uv.x) + angle;
    vec2 kaleidoscopeUv = vec2(radius * cos(theta), radius * sin(theta)) + 0.5;
    vec3 color = texture(uTexture, kaleidoscopeUv).rgb;
    gl_FragColor = vec4(color, 1.0);
}