// EDGE

uniform sampler2D uTexture;
varying vec2 vUv;

void main() {
  vec2 offset = vec2(1.0 / 256.0, 1.0 / 256.0); // Adjust this based on your video resolution
  vec4 color = texture2D(uTexture, vUv);
  vec4 colorUp = texture2D(uTexture, vUv + vec2(0.0, offset.y));
  vec4 colorDown = texture2D(uTexture, vUv - vec2(0.0, offset.y));
  vec4 colorLeft = texture2D(uTexture, vUv - vec2(offset.x, 0.0));
  vec4 colorRight = texture2D(uTexture, vUv + vec2(offset.x, 0.0));
  vec4 edge = color * 4.0 - (colorUp + colorDown + colorLeft + colorRight);
  gl_FragColor = vec4(vec3(length(edge.rgb)) * 4.0, color.a * 1.0);
}