// Tint
uniform sampler2D uTexture;
uniform float uTime;
varying vec2 vUv;

void main() {
    vec3 color = texture(uTexture, vUv).rgb;
    float hueShift = sin(uTime * 0.5) * 0.5 + 0.5;
    float angle = hueShift * 6.2831; // 2 * PI
    float s = sin(angle);
    float c = cos(angle);
    vec3 newColor = vec3(
        color.r * c - color.g * s,
        color.r * s + color.g * c,
        color.b
    );
    gl_FragColor = vec4(newColor, 1.0);
}