// Tourbillon 2
uniform sampler2D uTexture;
uniform float uTime;
varying vec2 vUv;

void main() {
    vec2 uv = vUv - 0.5;
    float radius = length(uv);
    float angle = atan(uv.y, uv.x) + uTime * 0.5;
    float pattern = max(0.5, sin(radius * 10.0 - angle * 5.0));
    vec3 color = texture(uTexture, vUv).rgb;
    gl_FragColor = vec4(color * pattern, 1.0);
}