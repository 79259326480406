// Color shift
uniform sampler2D uTexture;
uniform float uTime;
varying vec2 vUv;

void main() {
    vec2 uv = vUv;
    vec3 redChannel = texture(uTexture, uv + vec2(0.02, 0.0)).rgb;
    vec3 greenChannel = texture(uTexture, uv).rgb;
    vec3 blueChannel = texture(uTexture, uv - vec2(0.02, 0.0)).rgb;
    vec3 color = vec3(redChannel.r, greenChannel.g, blueChannel.b);
    gl_FragColor = vec4(color, 1.0);
}