// Heatmap
uniform sampler2D uTexture;
uniform float uTime;
varying vec2 vUv;

void main() {
    vec3 color = texture(uTexture, vUv).rgb;
    float intensity = (color.r + color.g + color.b) / 2.0;
    vec3 heatColor = vec3(intensity, 0.0, 1.0 - intensity);
    gl_FragColor = vec4(heatColor, 1.0);
}