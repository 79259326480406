uniform sampler2D uTexture;
uniform float uTime;
varying vec2 vUv;

void main() {
    vec3 color = texture(uTexture, vUv).rgb;
    color.g /= 60.0;
    float brightness = 1.0 * dot(color, vec3(0.299, 0.987, 0.114));
    vec2 uv = vec2(vUv.x, mod(vUv.y + brightness * 1.0, 1.0)) * 1.0;
    vec3 sortedColor = texture(uTexture, uv).rgb;
    gl_FragColor = vec4(sortedColor, 1.0);
}