import './style.css'
import ReactDOM from 'react-dom/client'
import { Canvas } from '@react-three/fiber'
import Experience from './Experience.jsx'
import App from './pages/PicturePage/index.jsx'
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";
import Portal from './pages/Portal/index.jsx'
import Effect from './pages/Effect/index.jsx'
import Official1 from './pages/Official1/index.jsx'
import Official2 from './pages/Official2/index.jsx'

const root = ReactDOM.createRoot(document.querySelector('#root'))

const router = createBrowserRouter([
  {
    path: "/",
    element: <Portal />,
  },
  {
    path: "/Official1",
    element: <Official1 />,
  },
  {
    path: "/Official2",
    element: <Official2 />,
  },
  {
    path: "*",
    element: <Effect />,
  },
]);
  

root.render(
    <RouterProvider router={router} />
)