// Tourbillon
uniform sampler2D uTexture;
uniform float uTime;
varying vec2 vUv;

void main() {
    vec2 uv = vUv - 0.5;
    float angle = uTime + length(uv) * 10.0;
    vec2 swirlUv = vec2(
        uv.x * cos(angle) - uv.y * sin(angle),
        uv.x * sin(angle) + uv.y * cos(angle)
    ) + 0.5;
    vec3 color = texture(uTexture, swirlUv).rgb;
    gl_FragColor = vec4(color, 1.0);
}