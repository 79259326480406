// Pincushion distortion with minimal central curvature
uniform sampler2D uTexture;
uniform float uTime;
varying vec2 vUv;

vec3 softLight(vec3 base, vec3 blend) {
    return mix(sqrt(base) * blend, 1.0 - (1.0 - base) * (1.0 - blend), base);
}

vec3 applySoftLight(vec3 color) {
    vec3 blendColor = vec3(0.9, 0.9, 0.9); // Lumière douce légèrement chaude
    return softLight(color, blendColor);
}

vec3 applyGlow(vec3 color, vec2 uv) {
    float brightness = dot(color, vec3(0.299, 0.587, 0.114)); // Luminance
    vec3 glow = color * pow(brightness, 2.0) * 0.1; // Lueur douce en fonction de la luminance
    return color + glow;
}

vec3 addWarmth(vec3 color) {
    vec3 warm = vec3(1.1, 1.0, 0.9); // Augmente légèrement les rouges et diminue les bleus
    return color * warm;
}

vec3 addFilmGrain(vec3 color, vec2 uv, float amount) {
    float grain = fract(sin(dot(uv.xy, vec2(12.9898, 78.233))) * 43758.5453);
    grain = mix(-amount, amount, grain); // Ajuste l'intensité du grain
    return color + grain;
}

vec3 adjustContrast(vec3 color, float contrast) {
    return (color - 0.5) * contrast + 0.5;
}

vec3 dynamicContrast(vec3 color) {
    float luminance = dot(color, vec3(0.299, 0.587, 0.114)); // Calcul de la luminance
    float contrastFactor = mix(1.0, 1.2, smoothstep(0.3, 0.7, luminance)); // Contraste en fonction de la luminance
    return adjustContrast(color, contrastFactor);
}

vec3 applyBloom(vec3 color, vec2 uv) {
    float brightness = dot(color, vec3(0.299, 0.587, 0.114));
    vec3 bloom = color * pow(max(brightness - 0.7, 0.0), 2.0) * 0.8;
    return clamp(color + bloom * 1.0, 0.0, 1.0);
}

vec3 adjustSaturation(vec3 color, float saturation) {
    float grey = dot(color, vec3(0.299, 0.587, 0.114));
    return mix(vec3(grey), color, saturation);
}

vec3 recoverHighlights(vec3 color) {
    float maxChannel = max(color.r, max(color.g, color.b));
    float recovery = smoothstep(0.9, 1.0, maxChannel);
    return mix(color, color * recovery, 0.5);
}

vec3 colorPop(vec3 color) {
    float saturation = 1.1; // Légère augmentation de la saturation
    return adjustSaturation(color, saturation);
}

vec3 balancedShadows(vec3 color) {
    vec3 shadows = pow(color, vec3(0.8)); // Éclaircit les ombres sans affecter les zones lumineuses
    return mix(color, shadows, 0.5); // Mélange équilibré entre la correction et l'original
}

void main() {
    vec2 uv = vUv;
    vec2 center = vec2(0.5);

    // Calculer la direction et la distance par rapport au centre
    vec2 dir = uv - center;
    float dist = length(dir);

    // Appliquer une distorsion de type "pincushion" qui affecte principalement les bords
    float pincushionEffect = 1.0 - 1.0 * pow(dist, 2.4); // Pincushion effect that increases with distance

    // Ajuster les UV en fonction de la distorsion
    uv = center + dir * pincushionEffect;

    // Appliquer la texture avec les UV modifiés
    vec3 color = texture(uTexture, uv).rgb;

    color = applyGlow(color, uv);
    // color = recoverHighlights(color);
    color = addFilmGrain(color, uv, 0.01);
    color = applySoftLight(color);
    color = dynamicContrast(color);

    color = applyBloom(color, uv);
    color = colorPop(color);
    color = balancedShadows(color);
    // color = addWarmth(color);

    gl_FragColor = vec4(color, 1.0);
}