// Center blur
uniform sampler2D uTexture;
uniform float uTime;
varying vec2 vUv;

void main() {
    vec2 uv = vUv;
    vec2 center = vec2(0.5);
    float dist = length(uv - center);
    float blurAmount = smoothstep(0.1, 0.2, dist);
    vec3 color = vec3(0.0);
    for (float angle = 0.0; angle < 6.283; angle += 0.1) {
        vec2 offset = vec2(cos(angle), sin(angle)) * blurAmount * 0.05;
        color += texture(uTexture, uv + offset).rgb;
    }
    color /= 62.8; // Nombre total d'échantillons (approximatif)
    gl_FragColor = vec4(color, 1.0);
}