// BLACK AND WHITE DISTORTION
uniform sampler2D uTexture;
uniform float uTime;
varying vec2 vUv;

void main() {
    vec2 uv = vUv;
    float timeFactor = sin(uTime * 0.5) * 0.1;
    vec2 offset = vec2(sin(uv.y * 10.0 + uTime) * timeFactor, cos(uv.x * 10.0 + uTime) * timeFactor);
    vec3 color = texture(uTexture, uv + offset).rgb;

    float gray = dot(color, vec3(0.299, 0.587, 0.114));
    gl_FragColor = vec4(vec3(gray), 1.0);
}