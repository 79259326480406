// Edge inverse
uniform sampler2D uTexture;
uniform float uTime;
varying vec2 vUv;

void main() {
    vec3 color = texture(uTexture, vUv).rgb;
    vec3 edge = vec3(0.0);
    vec2 offsets[8] = vec2[](
        vec2(-1.0,  0.0),
        vec2( 1.0,  0.0),
        vec2( 0.0, -1.0),
        vec2( 0.0,  1.0),
        vec2(-1.0, -1.0),
        vec2( 1.0, -1.0),
        vec2(-1.0,  1.0),
        vec2( 1.0,  1.0)
    );
    for (int i = 0; i < 8; i++) {
        edge += texture(uTexture, vUv + offsets[i] * 0.005).rgb;
    }
    edge = abs(color - edge / 8.0) * 5.0;
    gl_FragColor = vec4(1.0 - edge, 1.0);
}