// Dispalcement chelou
uniform sampler2D uTexture;
uniform float uTime;
varying vec2 vUv;

void main() {
    vec2 uv = vUv;
    vec3 displacement = texture(uTexture, vUv).rgb * 17.0;
    uv = displacement.xy * 0.01;
    vec3 color = texture(uTexture, uv).rgb;
    
    gl_FragColor = vec4(color, 1.0);
}