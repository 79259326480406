// Water chelou
uniform sampler2D uTexture;
uniform float uTime;
varying vec2 vUv;

void main() {
    vec2 uv = vUv;
    float ripple = sin(length(vUv - 0.5) * 10.0 - uTime) * 0.2;
    uv += ripple * normalize(vUv - 0.5);
    vec3 color = texture(uTexture, uv).rgb;
    gl_FragColor = vec4(color, 1.0);
}