
uniform float uTime;
varying vec2 vUv;

uniform sampler2D uTexture; // La texture à trier

void main()
{
    vec4 currentColor = texture(uTexture, vUv);
    float sort = 1.4;

      // Pseudo Pixel Sorting
    float sortThreshold = 1.0 - clamp(sort / 2.6, 0.0, 1.0);
    vec2 sortUv = vec2(vUv.x, sortThreshold);
    
    // Curved melting transition
    vec2 transitionUV = vUv;
    transitionUV.y += pow(sort, 2.0 + (sort * 2.0)) * vUv.x * fract(sin(dot(vec2(transitionUV.x), vec2(12.9, 78.2)))* 437.5);
    gl_FragColor = texture(uTexture, transitionUV);
    
    // Draw pixel sorting effect behind the melting transition
    if(transitionUV.y > 1.){
      gl_FragColor = texture(uTexture, sortUv);
    }else{
      gl_FragColor = texture(uTexture, vUv);
    }
}