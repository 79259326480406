// Effet chelou

uniform sampler2D uTexture;
varying vec2 vUv;

void main() {
  vec2 tex_offset = vec2(1.0) / vec2(16.0, 16.0); // Adjust based on your video resolution
  vec4 result = vec4(0.0);
  result += texture2D(uTexture, vUv + tex_offset * vec2(-1.0, -1.0)) * -1.0;
  result += texture2D(uTexture, vUv + tex_offset * vec2(0.0, -1.0)) * -1.0;
  result += texture2D(uTexture, vUv + tex_offset * vec2(1.0, -1.0)) * -1.0;
  result += texture2D(uTexture, vUv + tex_offset * vec2(-1.0, 0.0)) * -1.0;
  result += texture2D(uTexture, vUv + tex_offset * vec2(0.0, 0.0)) * 9.0;
  result += texture2D(uTexture, vUv + tex_offset * vec2(1.0, 0.0)) * -1.0;
  result += texture2D(uTexture, vUv + tex_offset * vec2(-1.0, 1.0)) * -1.0;
  result += texture2D(uTexture, vUv + tex_offset * vec2(0.0, 1.0)) * -1.0;
  result += texture2D(uTexture, vUv + tex_offset * vec2(1.0, 1.0)) * -1.0;
  result += 0.4;
  gl_FragColor = result;
}