import { Link } from 'react-router-dom'
import './index.scss'

const Portal = () => {
    return (
      <main className="portal-section">
        <Link to="/Official1">
          <span>Official 1</span>
        </Link>
        <Link to="/Official2">
          <span>Official 2</span>
        </Link>
        <Link to="/effect#BWDist">
          <span>Effet 1</span>
        </Link>
        <Link to="/effect#centerBlur">
          <span>Effet 2</span>
        </Link>
        <Link to="/effect#chelouFragment">
          <span>Effet 3</span>
        </Link>
        <Link to="/effect#colorSwirl">
          <span>Effet 4</span>
        </Link>
        <Link to="/effect#colorTint">
          <span>Effet 5</span>
        </Link>
        <Link to="/effect#dispF">
          <span>Effet 6</span>
        </Link>
        <Link to="/effect#disp2F">
          <span>Effet 7</span>
        </Link>
        <Link to="/effect#distortion">
          <span>Effet 8</span>
        </Link>
        <Link to="/effect#edge">
          <span>Effet 9</span>
        </Link>
        <Link to="/effect#edgeInvert">
          <span>Effet 10</span>
        </Link>
        <Link to="/effect#heatmap">
          <span>Effet 11</span>
        </Link>
        <Link to="/effect#fragment2">
          <span>Effet 12</span>
        </Link>
        <Link to="/effect#pixelsF">
          <span>Effet 13</span>
        </Link>
        <Link to="/effect#repetitionF">
          <span>Effet 14</span>
        </Link>
        <Link to="/effect#tintF">
          <span>Effet 15</span>
        </Link>
        <Link to="/effect#tourbillonF">
          <span>Effet 16</span>
        </Link>
        <Link to="/effect#tourbillon2F">
          <span>Effet 17</span>
        </Link>
        <Link to="/effect#tourneF">
          <span>Effet 18</span>
        </Link>
        <Link to="/effect#tvF">
          <span>Effet 19</span>
        </Link>
        <Link to="/effect#waterF">
          <span>Effet 20</span>
        </Link>
      </main>
    );
}

export default Portal