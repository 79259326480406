// Repetition
uniform sampler2D uTexture;
uniform float uTime;
varying vec2 vUv;

void main() {
    vec2 uv = vUv;
    vec3 color = texture(uTexture, uv).rgb;
    float sort = mod(uv.y * 8.0 + uTime * 1.0, 1.0);
    vec2 sortedUv = vec2(uv.x, sort);
    vec3 sortedColor = texture(uTexture, sortedUv).rgb;
    gl_FragColor = vec4(sortedColor, 1.0);
}