// Pixels
uniform sampler2D uTexture;
uniform float uTime;
varying vec2 vUv;

void main() {
    vec2 uv = vUv;
    float gridSize = 0.02;
    vec2 gridUv = floor(uv / gridSize) * gridSize;
    vec3 color = texture(uTexture, gridUv).rgb;
    gl_FragColor = vec4(color, 1.0);
}