// DISTORTION

uniform sampler2D uTexture;
uniform float uTime; // Add a time uniform for animation
varying vec2 vUv;

void main() {
  vec2 uv = vUv;
  uv.x += sin(uv.y * 20.0 + uTime) * 0.05; // Adjust the values for different distortion effects
  vec4 color = texture2D(uTexture, uv);
  gl_FragColor = color;
}